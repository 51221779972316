<template>
  <div>
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          icon
          variant="flat"
          v-bind="{ ...props, ...$attrs }"
          color="transparent"
          @click="openAssistant"
        >
          <v-icon :color="color">mdi-auto-fix</v-icon>
        </v-btn>
      </template>
      <span>{{ name }} starten</span>
    </v-tooltip>
    <v-dialog
      v-model="assistantDialog"
      fullscreen
      persistent
      no-click-animation
    >
      <v-card>
        <v-toolbar
          color="primary"
          density="compact"
        >
          <v-container>
            <v-row align="center">
              <v-col cols="auto">
                <v-toolbar-title>
                  {{ name }}
                </v-toolbar-title>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn
                  icon
                  @click="assistantDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
        <app-assistant
          :key="'assistant-' + key"
          class="mt-2"
          :slides="slides"
          @finish="finish"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: 'Assistent',
    },
    slides: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  emits: ['finish'],
  data() {
    return {
      assistantDialog: false,
      key: Date.now(),
    }
  },
  mounted() {
    const query = this.$route.query.assistant
    if (query && query === this.id) {
      this.assistantDialog = true
    }
  },
  methods: {
    openAssistant() {
      this.key = Date.now()
      this.assistantDialog = true
    },
    finish() {
      this.$emit('finish')
      this.assistantDialog = false
    },
  },
}
</script>
